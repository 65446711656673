<template>

  <q-page
    class="window-height window-width row justify-center items-center bg-primary">

    <AuthCardForm @submit="send">

      <BaseInput
        color="primary"
        dark
        label="Inserisci la mail di recupero"
        clearable
        clear-icon="mdi-close"
        type="email"
        no-error-icon
        error-name="email"
        :errors="errors"
        :stack-label="false"
        v-model="email"
        :rules="[val => !!val || 'Campo Obbligatorio']">

        <template #prepend>
          <q-icon name="mdi-email" />
        </template>
      </BaseInput>

      <div class="row justify-end">

        <BaseBtn
          type="submit"
          :disabled="loading"
          label="Invia"
          class="q-mt-sm full-width q-pa-sm"
          color="primary" />

      </div>

      <div class="row justify-end">

        <BaseBtn
          flat
          no-caps
          :to="{ name: 'login' }"
          label="Annulla"
          class="full-width"
          color="primary" />

      </div>

    </AuthCardForm>

    <BaseInnerLoading :showing="loading" />

  </q-page>

</template>

<script>
import AuthCardForm from '../components/auth/AuthCardForm.vue'
import useApiErrors from '../hooks/apiErrors.js'
import useAuth from '../hooks/auth.js'
import useNotify from '../hooks/notify.js'
import useToggler from '../hooks/toggler.js'
import { ref } from '@vue/composition-api'
import { router } from '../config/router.js'

export default {
  name: 'ForgotPassword',

  components: {
    AuthCardForm,
  },

  setup(){
    const { toggler : loading, on: showLoader, off: hideLoader } = useToggler()
    const { passwordRecovery } = useAuth()
    const { success } = useNotify()

    const errors = useApiErrors()
    const email = ref('')

    function send()
    {
      if( email.value ){

        showLoader()

        passwordRecovery( email.value )
          .then( response => {
            success( response.message )
            router.push( { name : 'login' } )
          })
          .catch( errors.setError )
          .finally( hideLoader )
      }
    }

    return {
      errors,
      email,
      loading,
      send,
    }
  },
}
</script>

